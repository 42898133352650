import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prerequisites`}</h2>
    <ul>
      <li parentName="ul">{`A user with access to Engagement Studio; `}</li>
      <li parentName="ul">{`Smart windows added to `}<em parentName="li">{`Settings`}</em>{` > `}<em parentName="li">{`Global`}</em>{` > `}<em parentName="li">{`Administration`}</em>{` > `}<em parentName="li">{`Product Management`}</em>{` in Engagement Studio for permissions;  `}</li>
      <li parentName="ul">{`Already completed the Campaigns course;`}</li>
      <li parentName="ul">{`A contact window already created (with the desired visitor information fields);`}</li>
      <li parentName="ul">{`At least a voice and/or chat skill created.`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`Smart windows are widgets added to an onsite campaign that can be incorporated in the client page to engage with customers.`}</p>
    <h2>{`Steps`}</h2>
    <h3>{`1. Add campaign`}</h3>
    <ol>
      <li parentName="ol">{`On the side bar of Engagement Studio, click `}<em parentName="li">{`AdCenter`}</em>{` (`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACe0lEQVQoz32QyWsTARTG5z8QVFwuKiq2dBFJBdGjqOCl3hREcVf8B8STUMW9HkTQghdXaOtS28nMtLbBYsFaWxubGmNrYpqETDJJprOkmWZp8pOmKnjxgx+893jv8fEJdTt2s+HiS7bdGKDpZh9bLktsbhGpvSJSd1Wk4Zqbhusi9ddFXLclXK0S2+9INLW62XVXYe+Dt+xr62fPPTc773QhrGvcxS3ZSyht8zVuEEhafP+DZhFM24xGMyj+GAFVxx/TCGqz9AbijM5oTMZ1ApqJTzU40T6EsGqLizdjQf6nChDVbaiUoVyASpGE7WBlc5QKhSrlUpEL0keENTVNdI5MVQ8LpQUWypV/WNRcvkh48SFQWhoR1i0iagJd19ENA9OyuCAPI6yucfHy03R1qbiwQPn3k6qzylLtFEtEZrOUi3mcuSyVYp64mcU0DBzLpJjLUcnPc2lgBGFtrYuWnhEmEyYjkTRedRbJH+V9UGU0msKr6nh+xOmbiqJZNtNahphhI09FaPs4waOxSR6P+3ni9XO2x4OwbH094pcQ0Xia+52DBEIqUTOH5cxjW1aVfKnE0E+N3m9J/vgfjiXY/7yb5nY3zR0iBzpEDr6SEFZu2srToUmeicMktRQPXwwyEUlhOw4z4TChUAgzm8UXzzAejP/NdTyhcei1zNFuhWM9CsfERWSE5Rsb6Rqbpve9j/4PX3n1doxwxmK+UEBPpchoGk6+QEw3sNPa33x9qTSHuyWOSwonFYVTvQqn+xSEFRsbafN4+RaM0OH5TCxt4tcMfDMqM2qSTHaORDbHeHSxT6BaNrM5h3fhCEd63JyUZc70yZzrlznvkfkF/n6e7vxCZiUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/e93cc/ec-menu.webp 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/b0544/ec-menu.webp 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/68fc1/ec-menu.webp 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/a2303/ec-menu.webp 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/4293a/ec-menu.webp 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/81194/ec-menu.webp 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d97bc1c5e7e45691e309288d886aeeda/eed55/ec-menu.png 300w", "/static/d97bc1c5e7e45691e309288d886aeeda/7491f/ec-menu.png 600w", "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png 1200w", "/static/d97bc1c5e7e45691e309288d886aeeda/d2cc9/ec-menu.png 1800w", "/static/d97bc1c5e7e45691e309288d886aeeda/00711/ec-menu.png 2400w", "/static/d97bc1c5e7e45691e309288d886aeeda/d0f99/ec-menu.png 3014w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d97bc1c5e7e45691e309288d886aeeda/8537d/ec-menu.png",
              "alt": "ec-menu",
              "title": "ec-menu",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Campaigns`}</em>{` on the menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1142px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "140.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEkklEQVRIx5XU6VNTZxTH8audVm3dkF2BmJBAglaxiw61HWfU1mlRrI4O1g3BIkIIEdkklkIJBWuxikAFlTUs2ZO7JAS3mfqmf9a3c28wgnUUXpx5Xj2fOef5nXuFzU0S66+LrG8Q+eS6yMYGkaQmiaRGkc2OJ2T+1Ik5z4xl916Mpc2YLvdgKndiKu/CcMmJsfx15V92Iqy2BTG0z6Nrm8PQHiO5RUGo9iNU+xDsEYQjjVirrlK49xs2fHuFz6pvUVDxG3mXOiisdPJ5VRe7KjvZU9WFpaIjDqbeUEhpUbRzQ4OEYA2wyupHqI/yUXELJT8cxbzrS1KPWsm/3MX2823knG8jr7wDY1m7dporOtBf/BVBu1wbYHVtAEFFavwaKNT4EK4prDl6g6SNSaRs1ZNWYsNU4eSr2h6KrD0U1S6UtZui2m7223oQVtcGyWxVyPklSkqzjHDVF0dVsE5h/Yk2vjt0mH1fHyS5uIbu4SmUcBCf34/H7SYQ8CPLMrIkIUmSCgZIbpZJbpLY3CiR1iJrwWigPQ5a8szkWgrZ+qON/kkvc7JEKBwmFAoRDoc1SFoMblnANjWIpN9Q2NQoLoSisLakFZPewDa9WQP7JjzEIjJhUUwgoigmSgPTWhQyWhUN08at9ic6XFviQJ+jI8tgIeuEjfuTHmJKHHyFvEKXjKzu3ge2oBZQIhS7wsfHb5Kfa0Rn2kH2yaXg4lFfj6yuTYvClib5dcKJlCOsK3Gwq6AAff6nGqi94btAtatXKWtvt3ht7IoGGnTbyc61oDtVR/+k592gOqKa6hp7aGEXl4687riDPEN8ZA10LaPDrJsRPqwL/n9kNZRjrRqYYyxg++k67Q2jikwoLC5JN5Fy/PIi6M0OSxwUqHto3q2BQzM+ns1FiMiyBr9ZglAb5K2lfobXoqw51oouK5tt+nx0p204BsZxeXyMzHgYnfUuqbFZ70KHby1/IuWdZgumgkINPNB4mzJnHxc6+7jojNeFzntcdN6jrKvvfR1GtMXemp5JRo6J3NI67ozNIIcCeL1evB4vPq+XWFTh6fyc9j0vC8xIzSA924jprJ3BKTVliWAoxHwsij8Q5NbdIe70P4qnvBwwMy3eoQoOTHmIyBKxuSjtt/rR7z+L5WAZeQcuUHCobPlgeraJvLN2+ifdvHgyx1+DI6QUnqSpvVf76/h8Aa40OFcG5qugy80/z+c5fMbOqcpWomKAkcePGR8bxeueWcHIKnjOzoDLw4v5OfZ8/zMO512iioTL5WJmehqfz79yUO3w5fN5jpyr51hZI/++fEEoGEQWw1rSKwLNCx0+i0UZfDhOyp6T2rsFAkFm3T5KqxzvX+zFKavg39Pxv83TJ/P0Djxmx+FycorOkLWvlC+KK98B1rwZipGdZfV0PxhnaGiE4eEhXBNjjI2OMPRogtGJGWLRyMr20HzeTufDKUYnZxl4MMTg8EOmZt2IsoQoy/iCIbVDP8JVN0LVLELVTKJWXZlGsAZZV9xERmo6Gdm5GE5XUdzyO829/TT0DtB4Z4Drf97n2h/3qFfrdh//AZ3y+NrIMEgYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/e93cc/ec-adCenter-campaign.webp 300w", "/static/9d58220d59f992bf224028c42f529ca9/b0544/ec-adCenter-campaign.webp 600w", "/static/9d58220d59f992bf224028c42f529ca9/7c99d/ec-adCenter-campaign.webp 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9d58220d59f992bf224028c42f529ca9/eed55/ec-adCenter-campaign.png 300w", "/static/9d58220d59f992bf224028c42f529ca9/7491f/ec-adCenter-campaign.png 600w", "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png 1142w"],
              "sizes": "(max-width: 1142px) 100vw, 1142px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9d58220d59f992bf224028c42f529ca9/006e5/ec-adCenter-campaign.png",
              "alt": "ec-adCenter-campaign",
              "title": "ec-adCenter-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Click `}<em parentName="p">{`Add`}</em>{` to open a new Campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACS0lEQVQoz4XR/UtTURzH8fsfRCgVKj1rkoIapgkRkihYQRAEQQRBIBb1oz2g9OAPItkDVNSydG5T0PSuvE7cZv1gqTnnSgnWNTcL+ik15ja33T3cvcOraEHRB158z4Fzzg+fI+SUlLO5pof0un7SayU21IhsvCySelVk0zWRLbUiaXUiGddFtt4ws+2WmR31ZnbWm8lu6KWgqY/Ce/3k3+6luLEbIW1vMdWmEUyOWXRDMs3DX2h570E/5qVtzIvB4cUw7sW4xoPJ6aFjwkv7+AydLi9dH79invrGJXEUITWzgG7HDCtR+W8SsbVlXAkTCYchmdT2XZNehIzcA4jO1QeTidWjyX9LxLWpxhPaTESjxBRFu9Uz5UHI2l9KVfs7dCMzPBxy82RYRjci83RU5tl7medjMi0OmdbxafTOZTIG17TG6JIxfZimzfUZvdPNFesowvbcIrJudpPdIJHT2Etek8S+uxKF9yWKHkiUPJI4+FjikE6itLmPshYL5a0WKvR9VBr7OWKyaI62rxBSM/MxjLgJRRTm/Ev4QhGNPxIloMTWBKMx/BGFhcASi+EIfiXKD98i8/4AixGFeELFOOlGSNmVh3litUM18Vtff48aj4OqoiZUrb9QSCEaXfkoq2cWIWV3Hq1vPzHv8/N9wcdcIPSH+eC6hWVL636Gwpq5YJBgKESX242QtqeA4juvOKwbpEw3QEXzAJUtVo61WTlutHGiw8bJThunXtg4Ldo489LOWcnOOYudqgE71TY7FwbtXHxj5/xrG78A0wOGSy6/ESYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/e93cc/ec-add-campaign.webp 300w", "/static/9e640e031871779b1f6dd619d333233f/b0544/ec-add-campaign.webp 600w", "/static/9e640e031871779b1f6dd619d333233f/68fc1/ec-add-campaign.webp 1200w", "/static/9e640e031871779b1f6dd619d333233f/a2303/ec-add-campaign.webp 1800w", "/static/9e640e031871779b1f6dd619d333233f/4293a/ec-add-campaign.webp 2400w", "/static/9e640e031871779b1f6dd619d333233f/cb4c3/ec-add-campaign.webp 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9e640e031871779b1f6dd619d333233f/eed55/ec-add-campaign.png 300w", "/static/9e640e031871779b1f6dd619d333233f/7491f/ec-add-campaign.png 600w", "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png 1200w", "/static/9e640e031871779b1f6dd619d333233f/d2cc9/ec-add-campaign.png 1800w", "/static/9e640e031871779b1f6dd619d333233f/00711/ec-add-campaign.png 2400w", "/static/9e640e031871779b1f6dd619d333233f/bb056/ec-add-campaign.png 2962w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9e640e031871779b1f6dd619d333233f/8537d/ec-add-campaign.png",
              "alt": "ec-add-campaign",
              "title": "ec-add-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
4. Fill in the name and select `}<em parentName="p">{`Onsite`}</em>{` campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aa72e7e07448e12adbe4ed4d9d1a0072/ebd60/ec-onsite-new-filled-in.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABbUlEQVQoz52Sz0sCQRTH97/t3rFz1yIqoVIhhegUGJZoEERQnTrUqcAfmeFSaunuzuz83G/MrOumKx188GHm7Xz3+94Mz1lb30C+fIr9fAm5YjmmUMpS/IdCCYfHJ9jc2oVTqTVgQgphEZxh1RiORnAuajWbUBqCMQ7GGKSUEEKAcYEg5CCMI+QyAxMKUimrN9HrfcBpNOIOtdazSlEUwZtM8O0F6H8O8eMThEKDcgUqDPE+CAV0FFm9Cdd1U0OllD1IDk2nBkKo/UkpDaVTpNIIAmL1STP9fn+5oRGYKyt7HWHzaIFEkzGs1+tzhsn1Pc+zHfq+b3Oto+maQinNGl4t6dA8ssEIzfdFowTOuS0kpEoNK9XLeGymBiYIIRiPx2Cc2719vxlJAQVKydzYuMbwrFpbaea4AmQEtEchdm5aOHjo4bn5DmfvqIivwQCtdgedbjfmbUr3z7rAS7NjuXt6xfb5PXL1R1Svb/EL6GY5MYQ9YnwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aa72e7e07448e12adbe4ed4d9d1a0072/e93cc/ec-onsite-new-filled-in.webp 300w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/b0544/ec-onsite-new-filled-in.webp 600w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/68fc1/ec-onsite-new-filled-in.webp 1200w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/480e2/ec-onsite-new-filled-in.webp 1322w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aa72e7e07448e12adbe4ed4d9d1a0072/eed55/ec-onsite-new-filled-in.png 300w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/7491f/ec-onsite-new-filled-in.png 600w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/8537d/ec-onsite-new-filled-in.png 1200w", "/static/aa72e7e07448e12adbe4ed4d9d1a0072/ebd60/ec-onsite-new-filled-in.png 1322w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/aa72e7e07448e12adbe4ed4d9d1a0072/8537d/ec-onsite-new-filled-in.png",
              "alt": "ec-onsite-new-filled-in",
              "title": "ec-onsite-new-filled-in",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required  and it is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Type`}</em>{`: Select `}<em parentName="li">{`Onsite`}</em>{` or `}<em parentName="li">{`Landing page`}</em>{` or `}<em parentName="li">{`A/B testing`}</em>{` or `}<em parentName="li">{`Offsite`}</em>{`. This field defines the type of campaign.`}</li>
      <li parentName="ul"><em parentName="li">{`Channel`}</em>{`: Select `}<em parentName="li">{`Default`}</em>{` or create a new one to add.`}</li>
      <li parentName="ul"><em parentName="li">{`Contact list`}</em>{`: You can choose an existing contact list, but it's not mandatory.`}</li>
    </ul>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`On the right bottom corner of form `}<em parentName="li">{`New campaign`}</em>{`, click button `}<em parentName="li">{`Accept`}</em>{`.`}</li>
    </ol>
    <h4>{`Result`}</h4>
    <p>{`After accepting, `}<em parentName="p">{`Edit campaign`}</em>{` is open.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9b584ae2fed6a3a938560c4c94f43bc3/3cc45/ec-edit-campaign-onsite.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABq0lEQVQoz42SSW/TQBiG8zuQkBBiEUUgLlzhDj+JRVS0UITghMQB8R9wCoFeuPTWLCVBXZBQixMrcb20KPYkXmbmQV5CEhSgr/TIM+OZ93tnqSytfOLico0z9wzOPjA499Dg/CODC8sGlx5XubxS5cpqlaUnVa4+rXJtbZ3rz9a58fw9N1/UuP1qgztvPnP3dY1bL99R6XQ9tg496qZP3fRomB7Nrk+rd0yzl32nbGdYUxqmQ9u0ebu5z+rGLvc/bFPhb9KS0yocJwSRZPPALgxTqVBa52SKooggFEg1Hc+QSqNmmVmTqWm5heE4kUSJJE5V3j4WMSJOiVOZU/yToDV6AVnhTA3LKQwTqYrqZYpRlBAlCamUcyilFhpOUrb6pWFRQecLsu2OhCBO0nwyJfofqDLhl0FpmJYDUkqEEAyHwzyl/o/Rnwnb9tyWFaEQBGHIMAgQUfz7GGYvYFF/Eqhz5Mw/G50mJONRjpanfzYTffN9KjuDE+pdl5bl0u77NHsOWz/6tKwj2n2XzsClY7t8tbO2w47jsesW7Hke+77Hnu9x8POEj4ff+QWSoTYx3ylK1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9b584ae2fed6a3a938560c4c94f43bc3/e93cc/ec-edit-campaign-onsite.webp 300w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/b0544/ec-edit-campaign-onsite.webp 600w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/68fc1/ec-edit-campaign-onsite.webp 1200w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/a2303/ec-edit-campaign-onsite.webp 1800w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/4293a/ec-edit-campaign-onsite.webp 2400w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/b41d4/ec-edit-campaign-onsite.webp 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9b584ae2fed6a3a938560c4c94f43bc3/eed55/ec-edit-campaign-onsite.png 300w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/7491f/ec-edit-campaign-onsite.png 600w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/8537d/ec-edit-campaign-onsite.png 1200w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/d2cc9/ec-edit-campaign-onsite.png 1800w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/00711/ec-edit-campaign-onsite.png 2400w", "/static/9b584ae2fed6a3a938560c4c94f43bc3/3cc45/ec-edit-campaign-onsite.png 2850w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9b584ae2fed6a3a938560c4c94f43bc3/8537d/ec-edit-campaign-onsite.png",
              "alt": "ec-edit-campaign-onsite",
              "title": "ec-edit-campaign-onsite",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3>{`2. Add a element`}</h3>
    <ol>
      <li parentName="ol">{`Go to tab `}<em parentName="li">{`Active elements`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9c2aeb7758f898652d83b7ec4bab16bf/7cd4f/ec-edit-campaign-active-element.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxklEQVQY043PTU8DIRSFYf7/1oW/xo1uXbpq1OlHTGPoTGcGWhi4l9eANbHqwps8gRzCSa557Cwv7yPdwbHufbPpPdvh1GyGev9td/R0h4m3fuTuacvN/TO3DytMWDJ/jmZQ4T+TUsZHYT04zBRSC0ULWgqlgKoyO09YUstUL8q1Un0rtj5gjqeFJMqSlZgE6yIupJbFrFdvtYBaUS7qIiLknCki7CePsS4gWj9JE5IQs7SiJF/np/xD3cqfz4zzjHOOV9vzAcOGhJQZZvrJAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9c2aeb7758f898652d83b7ec4bab16bf/e93cc/ec-edit-campaign-active-element.webp 300w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/b0544/ec-edit-campaign-active-element.webp 600w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/68fc1/ec-edit-campaign-active-element.webp 1200w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/a2303/ec-edit-campaign-active-element.webp 1800w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/4293a/ec-edit-campaign-active-element.webp 2400w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/6dd60/ec-edit-campaign-active-element.webp 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9c2aeb7758f898652d83b7ec4bab16bf/eed55/ec-edit-campaign-active-element.png 300w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/7491f/ec-edit-campaign-active-element.png 600w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/8537d/ec-edit-campaign-active-element.png 1200w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/d2cc9/ec-edit-campaign-active-element.png 1800w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/00711/ec-edit-campaign-active-element.png 2400w", "/static/9c2aeb7758f898652d83b7ec4bab16bf/7cd4f/ec-edit-campaign-active-element.png 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9c2aeb7758f898652d83b7ec4bab16bf/8537d/ec-edit-campaign-active-element.png",
              "alt": "ec-edit-campaign-active-element",
              "title": "ec-edit-campaign-active-element",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Click `}<em parentName="p">{`Add`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0e58afa7c509a2d30d91c8c950fddccb/1c412/ec-add-element.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPklEQVQoz6VSPU/DMBDNj0diYIaJASSGsiIEiNKk0CpdMiCFDkCRGBjYqBJ/np08dG4NaQtSBSc93enZ7/nsc7J/eIx0lOPyJsPVYIjr9A79bIR+FvMSw+2QPD49g8MYDbIG/42kLB9CQeTgvYfzHlLKAGstpFKQUoGI4JxbgTYmZNZFbTKdToNh0zQht20LIUSAJUJdL2oW8hrvY3DNh7JR1IUOu4ZMxgXuqHtINOoi7u9io8NYz+cfEMqAnEf7i0HkVt5wvcMYZC2EJlj/za0b/sRvdKiUQlVVi3cjghQCdV0Hfv3qWmt438A3LTxzbFiWZTCK02IhG7ChMeZrQMyHSS4nzLUOnOtcuEUym738+c+xFf/cg/wNu+kr7t81kl6vh6IokOc5JpPJ1hiNcwxuxwF7J+fYOTrD6UWKT1TcQp2fCHRuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e58afa7c509a2d30d91c8c950fddccb/e93cc/ec-add-element.webp 300w", "/static/0e58afa7c509a2d30d91c8c950fddccb/b0544/ec-add-element.webp 600w", "/static/0e58afa7c509a2d30d91c8c950fddccb/68fc1/ec-add-element.webp 1200w", "/static/0e58afa7c509a2d30d91c8c950fddccb/61c51/ec-add-element.webp 1276w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0e58afa7c509a2d30d91c8c950fddccb/eed55/ec-add-element.png 300w", "/static/0e58afa7c509a2d30d91c8c950fddccb/7491f/ec-add-element.png 600w", "/static/0e58afa7c509a2d30d91c8c950fddccb/8537d/ec-add-element.png 1200w", "/static/0e58afa7c509a2d30d91c8c950fddccb/1c412/ec-add-element.png 1276w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0e58afa7c509a2d30d91c8c950fddccb/8537d/ec-add-element.png",
              "alt": "ec-add-element",
              "title": "ec-add-element",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. On the right bottom corner of form `}<em parentName="p">{`Add element`}</em>{`, status `}<em parentName="p">{`on`}</em>{` and click button `}<em parentName="p">{`Accept`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d65f39def0568bf0d87ed4d6b383c799/ec8bd/ec-add-element-smart-window-fill.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABdklEQVQoz6WSSU8CQRCF54+bePHqQTx40osL0RvK0oNADAzq2Zs3wyLEwJBZ6H2eqR4GgmhitJMv3ZXqfnlV1d7pxTVY6wG37B53rIVas41as7PL/Yb6F6p+C51uHweHJXiT6RRGKwjBITjHf1av/wRvMBy6QGsNYy20MRBCQEoJpdT6rLXZYEyekxLWWnd2gr0evNFo5AJKFMJhGCKOY0ghEEcR0iRBZi2yzK72DFor8FVFxhi3B0GwLUgXi0VupTbgUkEoA6ntDspk7s2W4Hg8dgElCkEqcT4PMQkTvH+EWMRLCJ2BKwuuc5bKQmj7ncPxjkNyt0xTpNKi8Ewpm2VrimJ+dEiCRJqmSJIEXAhoJZ0wxUpJ10O76mXRQxKXSm8EB4PBehgECUZRlIty7oZD0LTJSX7PQEm5HgpWdQQ05dls/qc/J4q/9xZhr/6K85cFuo/P8MrlMtrtNhhj8H3/11TrDTQYw9lNBfsnVyhdVnBUOsYnrnU5H434xQ0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d65f39def0568bf0d87ed4d6b383c799/e93cc/ec-add-element-smart-window-fill.webp 300w", "/static/d65f39def0568bf0d87ed4d6b383c799/b0544/ec-add-element-smart-window-fill.webp 600w", "/static/d65f39def0568bf0d87ed4d6b383c799/68fc1/ec-add-element-smart-window-fill.webp 1200w", "/static/d65f39def0568bf0d87ed4d6b383c799/7188c/ec-add-element-smart-window-fill.webp 1306w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d65f39def0568bf0d87ed4d6b383c799/eed55/ec-add-element-smart-window-fill.png 300w", "/static/d65f39def0568bf0d87ed4d6b383c799/7491f/ec-add-element-smart-window-fill.png 600w", "/static/d65f39def0568bf0d87ed4d6b383c799/8537d/ec-add-element-smart-window-fill.png 1200w", "/static/d65f39def0568bf0d87ed4d6b383c799/ec8bd/ec-add-element-smart-window-fill.png 1306w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d65f39def0568bf0d87ed4d6b383c799/8537d/ec-add-element-smart-window-fill.png",
              "alt": "ec-add-element-smart-window-fill",
              "title": "ec-add-element-smart-window-fill",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Element type`}</em>{`: Select `}<em parentName="li">{`Active element`}</em>{`. `}</li>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: Give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Status`}</em>{`: `}<em parentName="li">{`On`}</em>{` or `}<em parentName="li">{`Off`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Active placeholder`}</em>{`: Select `}<em parentName="li">{`-new placeholder-`}</em>{`, if you want to create a new one or re-use an existing. `}
        <blockquote parentName="li">
          <p parentName="blockquote">{`Check `}<a parentName="p" {...{
              "href": "/configure-placeholder.md"
            }}><em parentName="a">{`Configure-placeholder`}</em></a>{` for additional information.`}</p>
        </blockquote>
      </li>
    </ul>
    <h4>{`Result`}</h4>
    <p>{`After accepting, the element is added to the campaign.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f61fc0e4f0f2dee924b8e27eb818fe55/7cd4f/ec-onsite-smart-edit-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABKElEQVQoz43OX0vCUBjH8b307roMuivdok3INxBCYVhmeWNocy7FuTnnn7NoRpogefaNORGTLnzgw+/wPOccHqXmhrz4EfVBRD2IaAQRr8PpjggzMZpuRDQ3WR+8Y/oCrfzG8a3NUaGBwr8lQa44tL4WSz4XP1S9MP1wJSVSSuJYri/M5nNm34t1f2u1IffF24+b44+/Gy7jVFLxTj8+cFMrnKJ4fZ+e69HtuZhth2bHwXE9HNfdSGdJul6q7/sk75Jzy7YxLQvbsii3HZSz0xN0NUNOy5K7SBlaFkPNou+5zCYzlbxhcKXr6JqKrqppZs65rlRRCq2Am86YYldQdCbc9SaUXEHJE9x7goe+oOwLHgeCSiJITHgaCp5HguooXGdNvJO32vwCMO/8DVSLxXgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f61fc0e4f0f2dee924b8e27eb818fe55/e93cc/ec-onsite-smart-edit-campaign.webp 300w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/b0544/ec-onsite-smart-edit-campaign.webp 600w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/68fc1/ec-onsite-smart-edit-campaign.webp 1200w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/a2303/ec-onsite-smart-edit-campaign.webp 1800w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/4293a/ec-onsite-smart-edit-campaign.webp 2400w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/6dd60/ec-onsite-smart-edit-campaign.webp 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f61fc0e4f0f2dee924b8e27eb818fe55/eed55/ec-onsite-smart-edit-campaign.png 300w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/7491f/ec-onsite-smart-edit-campaign.png 600w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/8537d/ec-onsite-smart-edit-campaign.png 1200w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/d2cc9/ec-onsite-smart-edit-campaign.png 1800w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/00711/ec-onsite-smart-edit-campaign.png 2400w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/7cd4f/ec-onsite-smart-edit-campaign.png 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f61fc0e4f0f2dee924b8e27eb818fe55/8537d/ec-onsite-smart-edit-campaign.png",
              "alt": "ec-onsite-smart-edit-campaign",
              "title": "ec-onsite-smart-edit-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3>{`3. Configure element by adding widget`}</h3>
    <ol>
      <li parentName="ol">{`On the bottom right of the tab `}<em parentName="li">{`Active elements`}</em>{` in `}<em parentName="li">{`Edit campaign`}</em>{`, click button `}<em parentName="li">{`Add new element`}</em>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f61fc0e4f0f2dee924b8e27eb818fe55/7cd4f/ec-onsite-smart-edit-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABKElEQVQoz43OX0vCUBjH8b307roMuivdok3INxBCYVhmeWNocy7FuTnnn7NoRpogefaNORGTLnzgw+/wPOccHqXmhrz4EfVBRD2IaAQRr8PpjggzMZpuRDQ3WR+8Y/oCrfzG8a3NUaGBwr8lQa44tL4WSz4XP1S9MP1wJSVSSuJYri/M5nNm34t1f2u1IffF24+b44+/Gy7jVFLxTj8+cFMrnKJ4fZ+e69HtuZhth2bHwXE9HNfdSGdJul6q7/sk75Jzy7YxLQvbsii3HZSz0xN0NUNOy5K7SBlaFkPNou+5zCYzlbxhcKXr6JqKrqppZs65rlRRCq2Am86YYldQdCbc9SaUXEHJE9x7goe+oOwLHgeCSiJITHgaCp5HguooXGdNvJO32vwCMO/8DVSLxXgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f61fc0e4f0f2dee924b8e27eb818fe55/e93cc/ec-onsite-smart-edit-campaign.webp 300w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/b0544/ec-onsite-smart-edit-campaign.webp 600w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/68fc1/ec-onsite-smart-edit-campaign.webp 1200w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/a2303/ec-onsite-smart-edit-campaign.webp 1800w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/4293a/ec-onsite-smart-edit-campaign.webp 2400w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/6dd60/ec-onsite-smart-edit-campaign.webp 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f61fc0e4f0f2dee924b8e27eb818fe55/eed55/ec-onsite-smart-edit-campaign.png 300w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/7491f/ec-onsite-smart-edit-campaign.png 600w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/8537d/ec-onsite-smart-edit-campaign.png 1200w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/d2cc9/ec-onsite-smart-edit-campaign.png 1800w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/00711/ec-onsite-smart-edit-campaign.png 2400w", "/static/f61fc0e4f0f2dee924b8e27eb818fe55/7cd4f/ec-onsite-smart-edit-campaign.png 2802w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f61fc0e4f0f2dee924b8e27eb818fe55/8537d/ec-onsite-smart-edit-campaign.png",
              "alt": "ec-onsite-smart-edit-campaign",
              "title": "ec-onsite-smart-edit-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
2. Scroll down until you find `}<em parentName="p">{`Smart Window`}</em>{` and select the widget.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/386e00f367c3cde77d1d3d66f774a736/1e78a/ec-add-element-list.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACjklEQVQ4y41UCW7bMBDU//9VtEiRxlcdS74l6qao04nvKYbCKobRAiUwIC1yZ2cvO9Pf7zgdjyirCsfTEff7Hbfbze5yfoR8f8T1egXXdu/Dmb179sfpdLIGsoTgfxZJuXZ+AGcxG+OjrXC+XHG7XnA+n63HNE0RRRHath3w+flpnfBecLlcrBjc79hToTt5RRKFWEcaUaZRVZUFyXa7HTabzbDv93sEQQDf9xHHsX1Dx3zfNA3W6zWcxfw3Um2wDjXi3NgLggYkIEiglBoQhiHKsoQxxu51XaPrOuvU8SavSOMQm9ggL/pLEtKQarTWKIrCGggYvjgmaMNvVuHy7TsKtcEqNjCmRNP0hAyHyrhnWWbVEBLePwm90Q8U0R5eZJBog7IiYWuJttutheSOqkku4VK5gIR9yNNfKLMIflJAJTlUom1PUt1iscByuYTneQNc17Xfeead3NNJTzh+gY4DrOM+nK5trDcqpKo8z60qghVNksQWTPIq+eTZhvw2niAsWuyi3Oavqr9yyFBpTBKSibHszB1hhXSdVepM5wso3UKlGqas+hZo+7Zh/zFvrDSVSkGE6JFwKMp47mGvO3hBCk/lUFmBWJcIAjU0NcHiMAUM/W/EXdtiRcLRdIbM1NjGup+W3KB+aBsBySSnz61DwsPhAG+1gjOZzeCnBcbrCCuV2vGrqwpKhZZIJkVIOSVCKu1DNHUNd7mE8zqaIdENNlEObfoq0ytDo7FUVWaX+SSZEEnIH4cDlgz552iOIG+xClNo8zV6JGPemEdpcDmTWJwIKXNoFU7GI2S5hqtyO8vNQ9vQmEplGqiOeG6dvn/bPofjl28okwBBVg5/XXwk/ffcIkLwDPbhu+viDz/XkQRyDGRKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/386e00f367c3cde77d1d3d66f774a736/e93cc/ec-add-element-list.webp 300w", "/static/386e00f367c3cde77d1d3d66f774a736/b0544/ec-add-element-list.webp 600w", "/static/386e00f367c3cde77d1d3d66f774a736/68fc1/ec-add-element-list.webp 1200w", "/static/386e00f367c3cde77d1d3d66f774a736/59a8d/ec-add-element-list.webp 1668w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/386e00f367c3cde77d1d3d66f774a736/eed55/ec-add-element-list.png 300w", "/static/386e00f367c3cde77d1d3d66f774a736/7491f/ec-add-element-list.png 600w", "/static/386e00f367c3cde77d1d3d66f774a736/8537d/ec-add-element-list.png 1200w", "/static/386e00f367c3cde77d1d3d66f774a736/1e78a/ec-add-element-list.png 1668w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/386e00f367c3cde77d1d3d66f774a736/8537d/ec-add-element-list.png",
              "alt": "ec-add-element-list",
              "title": "ec-add-element-list",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
3. Fill in the `}<em parentName="p">{`Name`}</em>{`, and `}<em parentName="p">{`Skill`}</em>{` and on the right bottom corner of form `}<em parentName="p">{`Configure element`}</em>{`, click button `}<em parentName="p">{`Accept`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ef119150d1609a2821ac004576b49d1e/58e7d/ec-smart-window-configure-element-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACD0lEQVQ4y4VUi5KaMBTl/z+s02lXKqvdmb7W1uoqrggBAuTFae8NUVB3NjNnQpKbcx85l2i92aJtG1RVhbIsoZRC3/cAAsBr5xzP92CtZbs4WSD6u31h467rGP6wh1IGnTLsYOB9c1jreP4czxGl6eHGoO8dhJBIjwK79BVZUUJUEi05sD2UcYzOODiO3kf4uFggOhw84TglrRWORYldTiQaTachO80E6gpuKAeN5XKJaL/fn+vkw7dcz1pKnPICeqgpXXLW3qC/Jtxut2dCBl0M0Y6/7yCchUdhwnHKNOhhsiyDlBJ1XaNtW75wDbInG2PMNMI0TSeE5JEMKe2m8XIiaK3Z5l3CEGFIgUgoMjLWxnCEFPW5jq4/w5g7NdxsNnyZ9Qawx7HX90Q4ruGCZBMeJWzSoKiatsMxtxClxuGoUFYaojK8zk4KhdAoKzO5O5vNEB2GGoaXuxj0iJcVfq4kPn0ReP4jES9KXs8eBb5+q/D9ufFZjSPc33kUqg8r/6nEat3iIRH49Vviw0OB1doTE+GPK0LulJfd/oZQdQ1eM4mP8Qmqk3Q6+llcQB1Fzu1wdzaf3xJS/eq6Ql1LFKLlPxA1P0tmELqXj5dN2PeEyYUwPD8R5HkOKWtYq9kBaXDSTW90yoQwHNJMhERMeiQQYRDzGKTZsM+ESXKbckjbp14z8bhTxiCbScr/Cf8BL1x3kkdTKCwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ef119150d1609a2821ac004576b49d1e/e93cc/ec-smart-window-configure-element-1.webp 300w", "/static/ef119150d1609a2821ac004576b49d1e/b0544/ec-smart-window-configure-element-1.webp 600w", "/static/ef119150d1609a2821ac004576b49d1e/68fc1/ec-smart-window-configure-element-1.webp 1200w", "/static/ef119150d1609a2821ac004576b49d1e/f9197/ec-smart-window-configure-element-1.webp 1662w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ef119150d1609a2821ac004576b49d1e/eed55/ec-smart-window-configure-element-1.png 300w", "/static/ef119150d1609a2821ac004576b49d1e/7491f/ec-smart-window-configure-element-1.png 600w", "/static/ef119150d1609a2821ac004576b49d1e/8537d/ec-smart-window-configure-element-1.png 1200w", "/static/ef119150d1609a2821ac004576b49d1e/58e7d/ec-smart-window-configure-element-1.png 1662w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ef119150d1609a2821ac004576b49d1e/8537d/ec-smart-window-configure-element-1.png",
              "alt": "ec-smart-window-configure-element-1",
              "title": "ec-smart-window-configure-element-1",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dd07281993c692ea0e193254aaa3a446/b51d9/ec-smart-window-configure-element-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAABqUlEQVQ4y32U206EMBCG9/1vXb1xSXwcb3wClcTsrtFQoIVCgRbG/IXBctidZNI2mX78cyiHp+iFYNY5GoZh3FtLVVVR09SklKKiKMgYQ33fk3NuXuu69vdwhn3EMR1moLV+BSjLMu+lbsla5y8ziB1nH68KKkXyDzw+L4FQorX26l7fMvr5leRcR30/LIDw2hgPHiaF758xHR5WQATmeU5SSjKmprZtNyB2ZOOCUq2AYwow1AzQrus2qYYpN00zn2fgMQCGTYGyWzB2xODKMAkZgacRqHXlV4auix8aYrzj411Hnam3wFAhDOmgRkgdjcKIsHMpEC1FSvJ62QeyMlwCECuAZVn6PYMB5LrJRFB+Od9XiAs83JhHDDd3Ex6WQgpBeajw8bQcG6xQgoJDGToOtdxRHvIFMFS4BiIQQK5hmqZ+Ju8Cr3tAt2wKP0MhhH85/LF9YJhytGwKoEgVDiCru6VQbVKOti+FDR0FmEuw93NQGJvvSWEcAqfacSOQJhz7tTI4au7nMEkoP3/tAK2bnxNGhaG8R+pwzCXOSsrxo0qRTsX8+/oDPwobsECdjUIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/dd07281993c692ea0e193254aaa3a446/e93cc/ec-smart-window-configure-element-2.webp 300w", "/static/dd07281993c692ea0e193254aaa3a446/b0544/ec-smart-window-configure-element-2.webp 600w", "/static/dd07281993c692ea0e193254aaa3a446/68fc1/ec-smart-window-configure-element-2.webp 1200w", "/static/dd07281993c692ea0e193254aaa3a446/3c47d/ec-smart-window-configure-element-2.webp 1698w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/dd07281993c692ea0e193254aaa3a446/eed55/ec-smart-window-configure-element-2.png 300w", "/static/dd07281993c692ea0e193254aaa3a446/7491f/ec-smart-window-configure-element-2.png 600w", "/static/dd07281993c692ea0e193254aaa3a446/8537d/ec-smart-window-configure-element-2.png 1200w", "/static/dd07281993c692ea0e193254aaa3a446/b51d9/ec-smart-window-configure-element-2.png 1698w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/dd07281993c692ea0e193254aaa3a446/8537d/ec-smart-window-configure-element-2.png",
              "alt": "ec-smart-window-configure-element-2",
              "title": "ec-smart-window-configure-element-2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b5fcd26c957b093eec19cf2029dcd5f4/013fe/ec-smart-window-configure-element-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9klEQVQY032PXWuDMBSG/b3rblev/DW72a8YjDJ2OxDabX4nKtMVUdEaY8w7cpidhdIDDydvAs85sdz9AaaUUhBCYBxHQkqJpmnoPM/zVdSs8RPHmEdBjruHLSx3/0FhUgrDMJCkqip0XYe2bdH3PUnNMPNu+sIoJ3x7HpQ4kWOzXQnNxKXXdU2ysiyR5zmyLCM45+dcFAXkpHBkHJMQf0LbCP+/bGRa63NOkgS+7yMIAkRRRN3kMAzBGKPN17WxbViHLw+3ahlwkVd3UgPe8YRKaNwb4cvuldZPGAPn6QVpasiukjCOzyDGm+vBedrh8fkdtuPgFx9HwFQhRC6qAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b5fcd26c957b093eec19cf2029dcd5f4/e93cc/ec-smart-window-configure-element-3.webp 300w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/b0544/ec-smart-window-configure-element-3.webp 600w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/68fc1/ec-smart-window-configure-element-3.webp 1200w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/8437c/ec-smart-window-configure-element-3.webp 1714w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b5fcd26c957b093eec19cf2029dcd5f4/eed55/ec-smart-window-configure-element-3.png 300w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/7491f/ec-smart-window-configure-element-3.png 600w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/8537d/ec-smart-window-configure-element-3.png 1200w", "/static/b5fcd26c957b093eec19cf2029dcd5f4/013fe/ec-smart-window-configure-element-3.png 1714w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b5fcd26c957b093eec19cf2029dcd5f4/8537d/ec-smart-window-configure-element-3.png",
              "alt": "ec-smart-window-configure-element-3",
              "title": "ec-smart-window-configure-element-3",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Name`}</em>{`: It is required and pre-filled with date and time. It is free text but you should give a meaningful name.`}</li>
      <li parentName="ul"><em parentName="li">{`Type of service`}</em>{`: Select `}<em parentName="li">{`chat`}</em>{` or/and `}<em parentName="li">{`call`}</em>{`. This field defines the type of communication channel available in the smart window.`}</li>
      <li parentName="ul"><em parentName="li">{`Functionality type`}</em>{`: Select `}<em parentName="li">{`Smart Window`}</em>{` for the e-commerce widget (extended window) and `}<em parentName="li">{`s
_Standard`}</em>{` for contact only.`}</li>
      <li parentName="ul"><em parentName="li">{`Contact window`}</em>{`: Select `}<em parentName="li">{`chat`}</em>{` or `}<em parentName="li">{`call`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Skill`}</em>{`: Select the skill.`}</li>
      <li parentName="ul"><em parentName="li">{`Show list of operators`}</em>{`: Select the number.`}</li>
      <li parentName="ul"><em parentName="li">{`Global Settings`}</em>{`: Select layout personalization according to client’s layout.
** There are options as `}<em parentName="li">{`Select icon`}</em>{` or `}<em parentName="li">{`Primary color`}</em>{` or `}<em parentName="li">{`Horizonta/Vertical Offset`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Localization`}</em>{`: For each available location, the text can be personalized. `}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Configure placeholder (as explained in `}<a parentName="li" {...{
          "href": "/configure-placeholder.md"
        }}><em parentName="a">{`Configure placeholder`}</em></a>{`).`}</li>
      <li parentName="ol">{`Define the segmentation rules on `}<em parentName="li">{`Segmentation`}</em>{` tab.`}</li>
      <li parentName="ol">{`Click button `}<em parentName="li">{`Save campaign`}</em>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4a2eaeb95138bc955931f142481788c1/15103/ec-save-campaign.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABAUlEQVQoz6WQS0/CQBSF5/9vXRofEexgWLpRkpJo7HRaujFxZUFYaKJCsRQIVDrzmfKwMWFh9CRf7pl7T+5kRqjuG/FrSvye0R1O6W15Gk3pjzZ15/tJxSApcxMGw5Tg8ZmDm5hDr4fIFjl7ZVYbfqFitSKZ5YznOWI8WwKWoigwxoC1GFOQTjIWy3zd283KusFUGIPFbldbRJrbH7fNDBj+LhHePxAGAVoHKN/n1lPcKR/f1+uz8vW311qvCcOQKIoIOx08pWi327iuSyeKEM3LK46OTzg9q+E4Dg0pkdJBOvspM7XzMrPNSclFo0G9Xue61UK8fMz5LP7zyErl530BPy0EK+WLBMoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4a2eaeb95138bc955931f142481788c1/e93cc/ec-save-campaign.webp 300w", "/static/4a2eaeb95138bc955931f142481788c1/b0544/ec-save-campaign.webp 600w", "/static/4a2eaeb95138bc955931f142481788c1/68fc1/ec-save-campaign.webp 1200w", "/static/4a2eaeb95138bc955931f142481788c1/a2303/ec-save-campaign.webp 1800w", "/static/4a2eaeb95138bc955931f142481788c1/4293a/ec-save-campaign.webp 2400w", "/static/4a2eaeb95138bc955931f142481788c1/55654/ec-save-campaign.webp 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4a2eaeb95138bc955931f142481788c1/eed55/ec-save-campaign.png 300w", "/static/4a2eaeb95138bc955931f142481788c1/7491f/ec-save-campaign.png 600w", "/static/4a2eaeb95138bc955931f142481788c1/8537d/ec-save-campaign.png 1200w", "/static/4a2eaeb95138bc955931f142481788c1/d2cc9/ec-save-campaign.png 1800w", "/static/4a2eaeb95138bc955931f142481788c1/00711/ec-save-campaign.png 2400w", "/static/4a2eaeb95138bc955931f142481788c1/15103/ec-save-campaign.png 2800w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4a2eaeb95138bc955931f142481788c1/8537d/ec-save-campaign.png",
              "alt": "ec-save-campaign",
              "title": "ec-save-campaign",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
7. Activate the campaign in `}<em parentName="p">{`Status`}</em>{` toggle.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/907879a2040e472c4f70165a7cf80724/1a70e/ec-online-smartwindow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABkElEQVQoz53Pz04TURTH8XkNEzEmBKJRN+7ckrjgRXwAl4aI1qAQMLLDF2DXUGbS6czQ+RvBWigSkU6BiCC6MO3QlvYSQ2far5lpMenCxHiST3LO7yT35EojUyrjKY3RZyrXnshcn5K58VTm5rTM6HOZsZTM+AuZWzMKt18q3HmlcHdW4d6cwv35LA8WNR6+NZlcMph4k0F6tLzJp9OA90dVCl9rfcc1iifBQC2x+a1v68ppvKvy8eQnK6UjZowyi46PtJD3+Xt1+ZeKwoj6RYfGRQdpdq2chL8uO3TCkDCMiLpdzlttGi2RzHE+LErEuzCK6PV6yRtxL722K0PXQuCS/6v4P9J0pkCpsE5ON9B0HVXTyeZ0tME8zEA3DPKmiWlZrOXzZFWVzOoq6XSa4vY2Ukr+QMExUXM5PMdmw3VYdx3euXafc8X509uWjWcPMtdlw/PwLIuK7yM9zpTY+XFG8Xud3apgt9bmcyDYCwTlM4FfF1RiDcF+U3DQFFSabfYbLQ7PBYetvi9twU414DeCcym9aE9jIQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/907879a2040e472c4f70165a7cf80724/e93cc/ec-online-smartwindow.webp 300w", "/static/907879a2040e472c4f70165a7cf80724/b0544/ec-online-smartwindow.webp 600w", "/static/907879a2040e472c4f70165a7cf80724/68fc1/ec-online-smartwindow.webp 1200w", "/static/907879a2040e472c4f70165a7cf80724/a2303/ec-online-smartwindow.webp 1800w", "/static/907879a2040e472c4f70165a7cf80724/4293a/ec-online-smartwindow.webp 2400w", "/static/907879a2040e472c4f70165a7cf80724/4f10c/ec-online-smartwindow.webp 2872w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/907879a2040e472c4f70165a7cf80724/eed55/ec-online-smartwindow.png 300w", "/static/907879a2040e472c4f70165a7cf80724/7491f/ec-online-smartwindow.png 600w", "/static/907879a2040e472c4f70165a7cf80724/8537d/ec-online-smartwindow.png 1200w", "/static/907879a2040e472c4f70165a7cf80724/d2cc9/ec-online-smartwindow.png 1800w", "/static/907879a2040e472c4f70165a7cf80724/00711/ec-online-smartwindow.png 2400w", "/static/907879a2040e472c4f70165a7cf80724/1a70e/ec-online-smartwindow.png 2872w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/907879a2040e472c4f70165a7cf80724/8537d/ec-online-smartwindow.png",
              "alt": "ec-online-smartwindow",
              "title": "ec-online-smartwindow",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Notes:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`By clicking on the eye, it is possible to preview the element.`}</li>
        <li parentName="ul">{`Make sure the element is activated by checking the element `}<em parentName="li">{`Status`}</em>{` toggle.`}</li>
      </ul>
    </blockquote>
    <h4>{`Result`}</h4>
    <p>{`After saving the campaign, the smart window is available according to the rules defined in the segmentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      